/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <span className='text-muted fw-bold d-block px-5'>{user.email}</span>
      <div className='separator my-2'></div>
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
          </div>

          <div className='d-block'>
            {user?.rolesString && user?.rolesString.map((role, roleIndex) => {
              return (
                <div className='' key={roleIndex}>
                  <div className='fw-bolder d-flex align-items-center fs-5 mb-2'>
                    <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{role}</span>
                  </div>
                  <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to='/logout' className='menu-link px-5'>
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export { HeaderUserMenu }

/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { FC, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { InfoModal } from '../../../../app/components/modals/InfoModal'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import EmergencyApi from '../../../../infrastructure/api/EmergencyApi'
import { IEmergencyRequestModel } from '../../../../models/requests/EmergencyRequestModel'
import { RootState } from '../../../../setup'
import { KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import { DefaultTitle } from '../header/page-title/DefaultTitle'

const Toolbar1: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel


  const { classes } = useLayout()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  async function confirmEmergency() {
    var model: IEmergencyRequestModel = {
      trainerId: user.id
    }
    await EmergencyApi.AddEmergency(model)
    window.location.reload()
  }

  return (
    <div className='toolbar py-5 py-lg-10' id='kt_toolbar'>
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        <DefaultTitle />
        <InfoModal
          title={"Emergency Request"}
          text={"All your lesson plans will be canceled and your emergency registration will be created."}
          show={show}
          handleClose={handleClose}
          confirm={confirmEmergency}
        />
        <div className='d-flex align-items-center py-1'>
          <div className=''>
            <a
              href='#'
              className='btn btn-danger btn-active-white btn-flex btn-color-white btn-active-color-white fw-bolder'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={handleShow}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen015.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1'
              />
              Emergency
            </a>

            {/* end::Menu */}
          </div>
          {/* end::Wrapper */}

        </div>
        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Toolbar1 }

import { IResultRequestModel } from '../../models/requests/ResultRequestModel'
import { IResultModel } from '../../models/responses/ResultModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class ResultApi {
  async GetResults(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IResultModel[]>>(`ex/Trainer/Results${generateQueryParams(filter)}`)
  }

  async GetResult(id: string) {
    return await API.get<IResultModel>('ex/Trainer/Results/' + id)
  }

  async UpdateResult(model: IResultRequestModel) {
    return await API.put('ex/Trainer/Results/' + model.id, model)
  }
}

export default new ResultApi()

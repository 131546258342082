import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { KTSVG } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import parse from 'html-react-parser'

type Props = {
    title: string
    text: string
    show: boolean
    handleClose: () => void
    confirm?: () => void
}
const InfoModal: FC<Props> = ({ title, text, show, handleClose, confirm = undefined }) => {
    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog-centered mw-650px h-auto'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-content'>
                <div className='modal-header pb-0 border-0 justify-content-end'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
                    </div>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                    <div className='text-center'>
                        <h1 className='mb-3'>{title}</h1>
                        <div className='text-muted fw-bold fs-5'>
                            {parse(text)}
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <button className='btn btn-primary' onClick={handleClose}>
                        {confirm == undefined ? 'Ok!' : 'Cancel'}
                    </button>
                    {confirm != undefined &&
                        <button
                            className='btn btn-danger'
                            onClick={() => {
                                handleClose()
                                confirm()
                            }}
                        >
                            Sure, do it!
                        </button>
                    }
                </div>
            </div>
        </Modal>
    )
}

export { InfoModal }

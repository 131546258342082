import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { SplashScreen } from '../../components/partials/SplashScreen'
import * as auth from './redux/AuthRedux'
import { logout } from './redux/AuthService'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(auth.actions.logout())
    logout();
  }, [dispatch])

  return (
    <SplashScreen></SplashScreen>

  )
}

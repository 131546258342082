import { FC, useEffect, useState } from 'react'
import AnnouncementApi from '../../../../infrastructure/api/AnnouncementApi'
import { IAnnouncementModel } from '../../../../models/responses/AnnouncementModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { SelectModel } from '../../../models/SelectModel'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import parse from 'html-react-parser'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const AnnouncementListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [announcements, setAnnouncements] = useState<PagedResponseModel<IAnnouncementModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')
  const [roles, setRoles] = useState<SelectModel[]>([])

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  useEffect(() => {
    async function getAnnouncements() {

      var responseRoles = await RoleApi.GetRoleDictionary()
      var dataRoles: SelectModel[] = [...responseRoles.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      setRoles(dataRoles)

      const paginationFilter: IPaginationFilter = {
        page: pageNumber,
        size: pageSize,
        orderBy: sort,
        search: searchText,
        filters: new Map()
      }

      let newFilters: Map<string, string> = new Map()

      var requests = user.rolesString!!.map(async (role, index) => {
        var responseRoles = await RoleApi.GetRoleDictionary()
        newFilters.set("Role", responseRoles.data.find(i => i.name == role)?.id.toString() || '')
        paginationFilter.filters = newFilters;
      });


      var response = await AnnouncementApi.GetAnnouncements(user.id, paginationFilter)
      setAnnouncements(response.data)
    }
    getAnnouncements()
  }, [refresh, pageNumber, pageSize, searchText, sort])


  return (
    <>
      <GenericTable
        title='Announcements'
        data={announcements?.items}
        currentTotalRecords={announcements?.totalRecords!!}
        currentPageNumber={announcements?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Date',
            name: 'sendTime'
          },
          {
            label: 'Announcement',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <div className='alert alert-dismissible bg-light-primary d-flex'>
                  <div className="d-flex flex-column text-dark pe-0 pe-sm-10">
                    <h5 className="mb-1">{announcements?.items[dataIndex].title}</h5>
                    <span>{parse(announcements!!.items[dataIndex].message)}
                    </span>
                  </div>
                </div>
              },
            },
          },
        ]}
        customOptions={{
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { AnnouncementListModal }

import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { SelectModel } from '../../../models/SelectModel'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetLevelDictionary: '[Set Level Dictionary] Action'
}

export interface ILevelState {
    levelDictionary?: SelectModel[],
}

const initialLevelState: ILevelState = {
    levelDictionary: []
}

export const reducer = persistReducer(
    {
        storage, key: 'v100-demo1-levels',
        whitelist: [],
        blacklist: ['levelDictionary']
    },
    (state: ILevelState = initialLevelState, action: ActionWithPayload<ILevelState>) => {
        switch (action.type) {
            case actionTypes.SetLevelDictionary: {
                const levelDictionary = action.payload?.levelDictionary
                return { ...state, levelDictionary }
            }
            default:
                return state
        }
    }
)

export const actions = {
    setLevelDictionary: (levelDictionary: Array<SelectModel>) => ({ type: actionTypes.SetLevelDictionary, payload: { levelDictionary } }),
}

import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { ScheduleListModal } from './_modals/ScheduleListModal'
import { TrainerCommentFormModal } from './_modals/TrainerCommentFormModal'

type Props = {
  //onClickItem: (value: string, name: string, type: string) => void
}

const SchedulePage: FC<Props> = () => {
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')


  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    switch (type) {
      default:
        handleShowForm()
        break
    }
  }



  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title fw-bolder text-dark'>Schedules</h3>
        </div>

        <div className='card-body py-3'>
          <ScheduleListModal refresh={refresh} onClickItem={onClickItem} />
          <TrainerCommentFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />

        </div>
      </div>
    </>
  )
}

const ScheduleWrapper: FC<Props> = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <SchedulePage />
    </>
  )
}

export { ScheduleWrapper }

import { IVideoModel } from '../../models/responses/VideoModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class VideoApi {

  async GetVideos(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IVideoModel[]>>(`e/Trainer/Videos${generateQueryParams(filter)}`)
  }
}

export default new VideoApi()

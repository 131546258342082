import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IBestTrainerModel, IScheduleModel, ISchedulesModel } from '../../models/responses/ScheduleModel'
import { IGetSchedulesRequestModel } from '../../models/requests/GetSchedulesRequestModel'
import { ITrainerCommentRequestModel } from '../../models/requests/TrainerCommentRequestModel'
import { IScheduleFormRequestModel, IScheduleStartRequestForm } from '../../models/requests/ScheduleFormRequestModel'

class ScheduleApi {
    async GetSchedule(id: string) {
        return await API.get<IScheduleModel>('s/Trainer/Schedules/' + id)
    }

    async GetBestTrainers() {
        return await API.get<IBestTrainerModel[]>('s/Trainer/Schedules/BestTrainers')
    }

    async GetPreviousSchedules(filter: IPaginationFilter, model: IGetSchedulesRequestModel) {
        return await API.post<PagedResponseModel<ISchedulesModel[]>>(`s/Trainer/Schedules/Previous/${generateQueryParams(filter)}`, model)
    }

    async AddTrainerComment(model: ITrainerCommentRequestModel) {
        return await API.post(`s/Trainer/Schedules/TrainerComment/${model.scheduleId}`, model)
    }

    async CompleteSchedule(model: IScheduleFormRequestModel) {
        return await API.put(`s/Trainer/Schedules/Complete/${model.scheduleId}`, model)
    }

    async StartSchedule(model: IScheduleStartRequestForm) {
        return await API.put(`s/Trainer/Schedules/Start/${model.scheduleId}`, model)
    }
}

export default new ScheduleApi()
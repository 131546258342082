import API from './Api'
import { ITrainerPerformanceModel } from '../../models/responses/TrainerPerformanceModel'
import { IGetTrainerPerformanceRequestModel } from '../../models/requests/GetTrainerPerformanceRequestModel'
import { ITrainerModel } from '../../models/responses/TrainerModel'

class TrainerApi {
    async GetTrainerPerformance(id: string, model: IGetTrainerPerformanceRequestModel) {
        return await API.post<ITrainerPerformanceModel[]>('s/Trainer/Trainers/Performance/' + id, model)
    }

    async GetTrainer(id: string) {
        return await API.get<ITrainerModel>('s/Trainer/Trainers/' + id)
    }
}

export default new TrainerApi()
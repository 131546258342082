import { ScheduleStatusEnum } from "../../enums/ScheduleStatusEnum"
import { ScheduleTypeForTrainerEnum } from "../../enums/ScheduleTypeForTrainerEnum"
import { DictionaryModel } from "../responses/DictionaryModel"
import { IPlacementQuestionAnswer } from "../responses/QuestionModel"

export interface IScheduleFormRequestModel {
    scheduleId?: string,
    type?: ScheduleTypeForTrainerEnum,
    status: ScheduleStatusEnum,
    scores?: DictionaryModel[],
    placementScores?: IPlacementQuestionAnswer[]
    directMessage?: string,
    privateMessage?: string
}

export interface IScheduleStartRequestForm {
    scheduleId?: string
}

export interface IScheduleFormModel {
    scheduleType?: ScheduleTypeForTrainerEnum,
    callQuality?: number,

    confidence?: number,
    attendance?: number,
    vocabularyUsage?: number,
    grammarUsage?: number,
    pronunciation?: number,
    fluency?: number,
    complexSentences?: number,
    accuracy?: number,
    advices?: number,

    directMessage?: string,
    privateMessage?: string
}


const scheduleFormRequestInitValues: IScheduleFormRequestModel = {
    scheduleId: undefined,
    status: ScheduleStatusEnum.Waiting,
    type: undefined,
    directMessage: undefined,
    privateMessage: undefined,
    scores: undefined
}

const scheduleFormInitValues: IScheduleFormModel = {

}

const scheduleStartFormInitValues: IScheduleStartRequestForm = {
    scheduleId: undefined
}

export { scheduleFormRequestInitValues, scheduleFormInitValues, scheduleStartFormInitValues }
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'

const QuickLinks: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
      >
        <h3 className='text-white fw-bold mb-3'>Quick Links</h3>
      </div>

      <div className='row g-0'>
        {user?.rolesString && user?.rolesString.find(i => i == "Administrator") &&
          <div className='col'>
            <a href={process.env.REACT_APP_ADMIN_URL} className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'>
              <KTSVG
                path='/media/icons/duotone/abstract/abs049.svg'
                className='svg-icon-3x svg-icon-primary mb-2'
              />
              <span className='fs-5 fw-bold text-gray-800 mb-0'>Admin</span>
              <span className='fs-7 text-gray-400'>Panel</span>
            </a>
          </div>
        }
        {user?.rolesString && user?.rolesString.find(i => i == "Trainer") &&
          <div className='col'>
            <a href={process.env.REACT_APP_TRAINER_URL} className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'>
              <KTSVG
                path='/media/icons/duotone/abstract/abs001.svg'
                className='svg-icon-3x svg-icon-primary mb-2'
              />
              <span className='fs-5 fw-bold text-gray-800 mb-0'>Trainer</span>
              <span className='fs-7 text-gray-400'>Panel</span>
            </a>
          </div>
        }
        <div className='col'>
          <a href={process.env.REACT_APP_STUDENT_URL} className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
            <KTSVG
              path='/media/icons/duotone/abstract/abs044.svg'
              className='svg-icon-3x svg-icon-primary mb-2'
            />
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Home</span>
            <span className='fs-7 text-gray-400'>Page</span>
          </a>
        </div>
      </div>
    </div>)
}

export { QuickLinks }

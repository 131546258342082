import { IRoleDictionaryModel } from '../../models/responses/RoleDictionaryModel'
import API from './Api'

class RoleApi {
  async GetRoleDictionary() {
    return await API.get<IRoleDictionaryModel[]>('i/Trainer/Roles/dictionary')
  }
}

export default new RoleApi()

import { FC, useEffect, useState } from 'react'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { IScheduleFormModel, IScheduleFormRequestModel, scheduleFormRequestInitValues } from '../../../../models/requests/ScheduleFormRequestModel'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import Select from 'react-select';
import { EnumToList } from '../../../helpers/EnumHelper'
import { RatingEnum } from '../../../../enums/RatingEnum'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { ScheduleTypeForTrainerEnum } from '../../../../enums/ScheduleTypeForTrainerEnum'
import { ScheduleScoreTypeEnum } from '../../../../enums/ScheduleScoreTypeEnum'
import { ScheduleStatusEnum } from '../../../../enums/ScheduleStatusEnum'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import ExamApi from '../../../../infrastructure/api/ExamApi'
import { IScheduleModel } from '../../../../models/responses/ScheduleModel'
import { IPlacementQuestionAnswer, IQuestionModel } from '../../../../models/responses/QuestionModel'
import * as level from '../../../pages/levels/redux/LevelRedux'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { BasicTable } from '../../../components/tables/BasicTable'
import { PlacementEvaluationEnum } from '../../../../enums/PlacementEvaluationEnum'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
var moment = require('moment');


type Props = {
  schedule: IScheduleModel
  refresh: number
}

const ScheduleFormModal: FC<Props> = ({ schedule, refresh }) => {
  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const ratingCallList = EnumToList(RatingEnum);
  const evaluationList = EnumToList(PlacementEvaluationEnum);

  const scheduleTypeForTrainerList = EnumToList(ScheduleTypeForTrainerEnum);
  const [scheduleTypeForTrainer, setScheduleTypeForTrainer] = useState(selectInitValues)
  const scheduleTypeList = EnumToList(ScheduleTypeEnum);
  const [scheduleType, setScheduleType] = useState(selectInitValues)

  const [confidence, setConfidence] = useState(selectInitValues)
  const [attendance, setAttendance] = useState(selectInitValues)
  const [vocabularyUsage, setVocabularyUsage] = useState(selectInitValues)
  const [grammarUsage, setGrammarUsage] = useState(selectInitValues)
  const [pronunciation, setPronunciation] = useState(selectInitValues)
  const [fluency, setFluency] = useState(selectInitValues)
  const [longAndComplexSentences, setLongAndComplexSentences] = useState(selectInitValues)
  const [accuracy, setAccuracy] = useState(selectInitValues)
  const [quality, setQuality] = useState(selectInitValues)
  const [advices, setAdvices] = useState(selectInitValues)

  const [submitType, setSubmitType] = useState<ScheduleStatusEnum>(ScheduleStatusEnum.Waiting)

  const [secondAfterStart, setSecondsAfterStart] = useState(0)
  const [secondWaitTimer, setSecondWaitTimer] = useState(0)

  const [trainerMessage, setTrainerMessage] = useState('')
  const [privateMessage, setPrivateMessage] = useState('')


  const [questions, setQuestions] = useState<IQuestionModel[]>([])
  const [answers, setAnswers] = useState<IPlacementQuestionAnswer[]>([])
  const dispatch = useDispatch()
  var checkFormControlSecond = 3 * 60;

  async function getPlacementExam(type: ScheduleTypeEnum, levelId: number) {
    var result = await ExamApi.GetPlacementExam(ScheduleTypeEnum[type], levelId);
    setQuestions(result.data)
    if (answers.length < 1) {
      var initQuestionAnswer: IPlacementQuestionAnswer[] = result.data.map((item, index) => {
        return { levelId: item.levelId, score: 0 }
      })

      setAnswers(initQuestionAnswer)
    }
  }

  async function init() {
    var startedDate = moment(schedule.details[schedule.details.length - 1].createdAt)
    var now = moment()
    setSecondsAfterStart(now.diff(startedDate, 'seconds'));
    if (now.diff(startedDate, 'seconds') <= checkFormControlSecond) {
      startTimer((checkFormControlSecond - now.diff(startedDate, 'seconds')));
    }

    setScheduleTypeForTrainer(scheduleTypeForTrainerList.find(i => i.value == schedule?.type.toString()) || selectInitValues)
    setScheduleType(scheduleTypeList.find(i => i.value == schedule?.type.toString()) || selectInitValues);
    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    }

  }

  useEffect(() => {
    init()

    if (
      schedule.type == ScheduleTypeEnum.Demo
      || schedule.type == ScheduleTypeEnum.PlacementPresent
    ) {
      getPlacementExam(schedule.type, schedule.levelId || 0);
    }


  }, [refresh])


  const formik = useFormik<IScheduleFormModel>({
    initialValues: scheduleFormRequestInitValues,
    enableReinitialize: true,
    //validationSchema: StudentPlanFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      if (submitType == ScheduleStatusEnum.Completed &&
        (privateMessage.length < 1
          || quality.value == ""
          || confidence.value == ""
          || attendance.value == ""
          || vocabularyUsage.value == ""
          || grammarUsage.value == ""
          || pronunciation.value == ""
          || fluency.value == ""
          || longAndComplexSentences.value == ""
          || accuracy.value == ""
          || advices.value == "")) {
        alert("Please fill in the required fields.")
        setLoading(false)
      } else {
        setTimeout(async () => {
          let model: IScheduleFormRequestModel = {
            scheduleId: schedule.id,
            status: submitType,
            type: parseInt(scheduleTypeForTrainer.value),
            directMessage: trainerMessage,
            privateMessage: privateMessage,
            placementScores: answers,
            scores: [
              { type: ScheduleScoreTypeEnum.CallQuality.toString(), value: quality.value },
              { type: ScheduleScoreTypeEnum.Confidence.toString(), value: confidence.value },
              { type: ScheduleScoreTypeEnum.Attendance.toString(), value: attendance.value },
              { type: ScheduleScoreTypeEnum.VocabularyUsage.toString(), value: vocabularyUsage.value },
              { type: ScheduleScoreTypeEnum.GrammarUsage.toString(), value: grammarUsage.value },
              { type: ScheduleScoreTypeEnum.Pronunciation.toString(), value: pronunciation.value },
              { type: ScheduleScoreTypeEnum.Fluency.toString(), value: fluency.value },
              { type: ScheduleScoreTypeEnum.LongAndComplexSentences.toString(), value: longAndComplexSentences.value },
              { type: ScheduleScoreTypeEnum.Accuracy.toString(), value: accuracy.value },
              { type: ScheduleScoreTypeEnum.Advices.toString(), value: advices.value },
            ],

          }
          process(model)
        }, 500)
      }
    },
  })

  async function process(model: IScheduleFormRequestModel) {
    try {
      var result = undefined
      if (model.scheduleId === undefined || model.scheduleId === '')
        result = undefined
      else {
        result = await ScheduleApi.CompleteSchedule(model)
      }
      formik.resetForm()
      document.location.href = "/dashboard"

    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  function startTimer(duration: number) {
    var counter = duration;
    let intervalId = setInterval(() => {
      counter = counter - 1;
      setSecondWaitTimer(counter);
      var startedDate = moment(schedule.details[schedule.details.length - 1].createdAt)
      var now = moment()
      setSecondsAfterStart(now.diff(startedDate, 'seconds'));

      if (counter === 0) clearInterval(intervalId)
    }, 1000)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder text-dark'>Active Call</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Form</span>
          </h3>
        </div>
        {(secondAfterStart < checkFormControlSecond) ?
          <div className='card-body'>
            <div className='text-center'>Preparing the form ..
              <span><h1>{secondWaitTimer} sn</h1></span>
            </div>
          </div>
          :
          <>
            <div className='card-body py-3'>

              <div className='mb-10'>
                <div className='row mb-5'>
                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Call About</label>
                    <Select
                      {...formik.getFieldProps('scheduleTypeForTrainer')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setScheduleTypeForTrainer(event!)}
                      value={scheduleTypeForTrainer}
                      options={scheduleTypeForTrainerList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.label}`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.scheduleType} error={formik.errors.scheduleType} />
                  </div>
                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Communication Quality</label>
                    <Select
                      {...formik.getFieldProps('callQuality')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setQuality(event!)}
                      value={quality}
                      options={ratingCallList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.value.toString()} Point`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.callQuality} error={formik.errors.callQuality} />
                  </div>
                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Advices</label>
                    <Select
                      {...formik.getFieldProps('advices')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setAdvices(event!)}
                      value={advices}
                      options={ratingCallList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.value.toString()} Point`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.advices} error={formik.errors.advices} />
                  </div>
                </div>
                <div className='row mb-5'>

                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Confidence</label>
                    <Select
                      {...formik.getFieldProps('accuracy')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setConfidence(event!)}
                      value={confidence}
                      options={ratingCallList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.value.toString()} Point`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.accuracy} error={formik.errors.accuracy} />
                  </div>
                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Attendance</label>
                    <Select
                      {...formik.getFieldProps('attendance')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setAttendance(event!)}
                      value={attendance}
                      options={ratingCallList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.value.toString()} Point`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.attendance} error={formik.errors.attendance} />
                  </div>
                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Vocabulary</label>
                    <Select
                      {...formik.getFieldProps('vocabularyUsage')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setVocabularyUsage(event!)}
                      value={vocabularyUsage}
                      options={ratingCallList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.value.toString()} Point`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.vocabularyUsage} error={formik.errors.vocabularyUsage} />
                  </div>
                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Grammar</label>
                    <Select
                      {...formik.getFieldProps('grammarUsage')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setGrammarUsage(event!)}
                      value={grammarUsage}
                      options={ratingCallList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.value.toString()} Point`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.grammarUsage} error={formik.errors.grammarUsage} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Pronunciation</label>
                    <Select
                      {...formik.getFieldProps('pronunciation')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setPronunciation(event!)}
                      value={pronunciation}
                      options={ratingCallList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.value.toString()} Point`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.pronunciation} error={formik.errors.pronunciation} />
                  </div>
                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Fluency</label>
                    <Select
                      {...formik.getFieldProps('fluency')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setFluency(event!)}
                      value={fluency}
                      options={ratingCallList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.value.toString()} Point`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.fluency} error={formik.errors.fluency} />
                  </div>
                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Long&Complex</label>
                    <Select
                      {...formik.getFieldProps('complexSentences')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setLongAndComplexSentences(event!)}
                      value={longAndComplexSentences}
                      options={ratingCallList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.value.toString()} Point`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.complexSentences} error={formik.errors.complexSentences} />
                  </div>
                  <div className='col fv-row'>
                    <label className='form-label fw-bolder required'>Accuracy</label>
                    <Select
                      {...formik.getFieldProps('accuracy')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => setAccuracy(event!)}
                      value={accuracy}
                      options={ratingCallList.map((d) => ({
                        value: d.value.toString(),
                        label: `${d.value.toString()} Point`
                      }))}
                    />
                    <FormikValidationError touched={formik.touched.accuracy} error={formik.errors.accuracy} />
                  </div>

                </div>
              </div>
              <hr />
              <div className='row mb-5'>
                <div className='col-lg-12'>
                  <label className='form-label fw-bolder required'>Notes and Interesting points about this call. We have to give some brief information for other trainers.
                    (PS: This part is not for student comments and please do not use punctuation)</label>
                  <textarea
                    className='form-control'
                    rows={2}
                    onChange={(e) => setPrivateMessage(e.target.value)}
                    placeholder='Reply..'
                  >{privateMessage}</textarea>
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-lg-12'>
                  <label className='form-label fw-bolder required'>If you have direct message to student use here please.</label>
                  <textarea
                    className='form-control'
                    rows={2}
                    onChange={(e) => setTrainerMessage(e.target.value)}
                    placeholder='Reply..'
                  >{trainerMessage}</textarea>
                </div>
              </div>

              <div className='row mb-5'>
                <BasicTable
                  title='Placement Rating'
                  data={questions}
                  customOptions={
                    { pagination: false, search: false, viewColumns: false, count: questions.length, rowsPerPage: 100 }
                  }
                  columns={[
                    {
                      label: '#',
                      name: '#',
                      options: {
                        customBodyRenderLite: (dataIndex) => {
                          return <>{dataIndex + 1}</>
                        },
                        sort: false,
                        viewColumns: false,
                      },
                    },
                    {
                      label: 'Question',
                      name: 'title',
                    },
                    {
                      label: 'Level',
                      name: 'levelId',
                      options: {
                        customBodyRender(value, tableMeta, updateValue) {
                          return <span className='badge badge-success'>{levelDictionary.find(j => j.value == value.toString())?.label}</span>
                        },
                      }
                    },
                    {
                      label: 'Evaluation',
                      name: '#',
                      options: {
                        customBodyRenderLite(dataIndex, rowIndex) {
                          return <>
                            <Select
                              styles={customSelectStyles(true)}
                              menuPortalTarget={document.body}
                              onChange={(event) => {
                                var newAnswers = answers;
                                newAnswers[dataIndex] = {
                                  levelId: questions[dataIndex].levelId,
                                  score: parseInt(event?.value!!) || 0
                                }
                                setAnswers(newAnswers)
                              }}
                              defaultValue={evaluationList?.find(i => i.value == answers![dataIndex]?.toString()) || evaluationList[0]}
                              options={evaluationList.map((d) => ({
                                value: d.value.toString(),
                                label: `${d.label}`
                              }))}
                            /></>
                        },
                      }
                    },
                  ]}
                />
              </div>

            </div>
            <div className='card-footer d-flex justify-content-start py-6 px-9'>
              <div className='col-8'>
                <div className='d-flex justify-content-start'>
                  <SaveChangesButton
                    loading={loading}
                    setLoading={setLoading}
                    complete={complete}
                    title={"Canceled"}
                    customClasses={"btn-info me-3"}
                    setComplete={setComplete}
                    valid={formik.isValid}
                    submit={formik.isSubmitting}
                    setSubmit={() => {
                      setSubmitType(ScheduleStatusEnum.Canceled);
                      formik.submitForm()
                    }}
                  />
                  {schedule != undefined && schedule?.meeting == undefined &&

                    <SaveChangesButton
                      loading={loading}
                      setLoading={setLoading}
                      complete={complete}
                      title={"Not Answered"}
                      customClasses={"btn-warning me-3"}
                      setComplete={setComplete}
                      valid={formik.isValid}
                      submit={formik.isSubmitting}
                      setSubmit={() => {
                        setSubmitType(ScheduleStatusEnum.NotAnswered);
                        formik.submitForm()
                      }} />
                  }
                  <SaveChangesButton
                    loading={loading}
                    setLoading={setLoading}
                    complete={complete}
                    title={"Technical Difficulty"}
                    customClasses={"btn-danger"}
                    setComplete={setComplete}
                    valid={formik.isValid}
                    submit={formik.isSubmitting}
                    setSubmit={() => {
                      setSubmitType(ScheduleStatusEnum.TechnicalDifficulty);
                      formik.submitForm()
                    }} />
                </div>
              </div>
              <div className='col'>
                <div className='d-flex justify-content-end'>
                  <SaveChangesButton
                    loading={loading}
                    setLoading={setLoading}
                    complete={complete}
                    title={"Completed"}
                    setComplete={setComplete}
                    valid={formik.isValid}
                    submit={formik.isSubmitting}
                    setSubmit={() => {
                      setSubmitType(ScheduleStatusEnum.Completed);
                      formik.submitForm()
                    }} />
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

export { ScheduleFormModal }

import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { TutorialWrapper } from '../pages/tutorials/TutorialWrapper'
import { AnnouncementWrapper } from '../pages/announcements/AnnouncementWrapper'
import { PerformanceWrapper } from '../pages/performance/PerformanceWrapper'
import { ActiveCallWrapper } from '../pages/schedules/ActiveCallWrapper'
import { CallPlanWrapper } from '../pages/callplans/CallPlanWrapper'

const PrivateRoutes = () => {
    const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

    return (
        <>
            <Routes>
                <Route element={<MasterLayout />}>

                    {/* Redirect to Dashboard after success login/registartion */}
                    <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                    {/* Pages */}
                    <Route path='dashboard' element={<DashboardWrapper />} />
                    <Route path='call/:scheduleId' element={<ActiveCallWrapper />} />
                    <Route path='callplans' element={<CallPlanWrapper />} />
                    <Route path='announcements' element={<AnnouncementWrapper />} />
                    <Route path='performance' element={<PerformanceWrapper />} />

                    <Route path='tutorials' element={<TutorialWrapper />} />

                    <Route path='builder' element={<BuilderPageWrapper />} />
                    <Route path='menu-test' element={<MenuTestPage />} />
                    {/* Lazy Modules */}
                    <Route
                        path='crafted/pages/profile/*'
                        element={
                            <SuspensedView>
                                <ProfilePage />
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='crafted/pages/wizards/*'
                        element={
                            <SuspensedView>
                                <WizardsPage />
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='crafted/widgets/*'
                        element={
                            <SuspensedView>
                                <WidgetsPage />
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='crafted/account/*'
                        element={
                            <SuspensedView>
                                <AccountPage />
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='apps/chat/*'
                        element={
                            <SuspensedView>
                                <ChatPage />
                            </SuspensedView>
                        }
                    />
                    {/* Page Not Found */}
                    <Route path='*' element={<Navigate to='/error/404' />} />
                </Route>
            </Routes>
        </>
    )
}

const SuspensedView: FC = ({ children }) => {
    TopBarProgress.config({
        barThickness: 3,
        shadowBlur: 5,
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

export enum ClaimTypeEnum {
    Photo = "Photo",
    PhoneNumber = "PhoneNumber",
    Email = "Email",
    TerminalNumber = "TerminalNumber",
    Birthday = "Birthday",
    Address = "Address",
    Zipcode = "Zipcode",
    Gender = "Gender",
    CountryId = "CountryId",
    CityId = "CityId",
    StateId = "StateId",

    CompanyId = "CompanyId",
    IsTestAccount = "IsTestAccount",
    MarketerCode = "MarketerCode",
    ReferenceCode = "ReferenceCode",
    WhoReference = "WhoReference",

    IsStudent = "IsStudent",
    IsAggrementAccepted = "IsAggrementAccepted",
    IsSmsActive = "IsSmsActive",
    IsEmailActive = "IsEmailActive",
    IsSupportByEducall = "IsSupportByEducall",
    IsSupportByCompany = "IsSupportByCompany",
    IsSafeExam = "IsSafeExam",
    ExamFinisher = "ExamFinisher",
    Edupoint = "Edupoint",

    ParentId = "ParentId",
    ConsultantId = "ConsultantId",

    PresentLevelId = "PresentLevelId",
    StartupLevelId = "StartupLevelId"
}
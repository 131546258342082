/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { SelectModel } from '../../../models/SelectModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import ResultApi from '../../../../infrastructure/api/ResultApi'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IResultModel } from '../../../../models/responses/ResultModel'
import { GenericTable } from '../../../components/tables/GenericTable'
import * as level from '../../../pages/levels/redux/LevelRedux'

import { KTSVG } from '../../../../_metronic/helpers'


type Props = {
  studentId: string
}

export const StudentResultListModal: FC<Props> = ({ studentId }) => {

  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
  const examDictionary: SelectModel[] = useSelector<RootState>(({ exam }) => exam.examDictionary, shallowEqual) as SelectModel[]
  const examTypeDictionary: SelectModel[] = useSelector<RootState>(({ exam }) => exam.examTypeDictionary, shallowEqual) as SelectModel[]
  const unitDictionary: SelectModel[] = useSelector<RootState>(({ unit }) => unit.unitDictionary, shallowEqual) as SelectModel[]

  const [selectedLevel, setSelectedLevel] = useState<SelectModel>()

  const dispatch = useDispatch()
  const [results, setResults] = useState<PagedResponseModel<IResultModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('endTime_desc')
  const [filters, setFilters] = useState<Map<string, string>>(new Map())

  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => setShowForm(false)
  const handleShowForm = () => setShowForm(true)

  const [showView, setShowView] = useState(false)
  const handleCloseView = () => setShowView(false)
  const handleShowView = () => setShowView(true)

  const init = (async () => {
    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    }

    if (studentId != '') {
      var lastList = filters;
      lastList.set("StudentId", studentId)

      const paginationFilter: IPaginationFilter = {
        page: pageNumber,
        size: pageSize,
        orderBy: sort,
        search: searchText,
        filters: lastList
      }
      var response = await ResultApi.GetResults(paginationFilter)
      setResults(response.data)
    }
  });

  useEffect(() => {
    init()
  }, [studentId, pageNumber, pageSize, searchText, sort, filters])


  return (
    <>
      <h5 className='fw-bolder m-0'>Exam Results</h5>

      <GenericTable
        title=''
        data={results?.items}
        currentTotalRecords={results?.totalRecords!!}
        currentPageNumber={results?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              filter: false,
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Title',
            name: '',
            options: {
              filter: false,
              customBodyRenderLite: (dataIndex) => {
                return <div className='d-flex align-items-center'>
                  <span className="badge bg-light-dark text-dark me-1">{examTypeDictionary.find(i => i.value == results?.items[dataIndex].examTypeId)?.label}</span> {results?.items[dataIndex]?.title.toUpperCase()}
                </div>
              },
            },
          },
          {
            label: 'Correct',
            name: 'totalCorrect', options: { filter: false }
          },
          {
            label: 'Wrong',
            name: 'totalWrong', options: { filter: false }
          },
          {
            label: 'Empty',
            name: 'totalEmpty', options: { filter: false }
          },
          {
            label: 'Success',
            name: 'success', options: {
              filter: false,
              customBodyRenderLite: (dataIndex) => {
                return (
                  <b>
                    {results?.items[dataIndex].success}
                    {!results?.items[dataIndex].isEvaluated && results?.items[dataIndex].isWriting ?
                      <><KTSVG path='/media/icons/duotone/General/Dislike.svg' className='svg-icon svg-icon-danger' /> Not Evaluated</>
                      : <></>
                    }
                  </b>
                )
              }
            },
          },
          {
            label: 'Date',
            name: 'endTime', options: { filter: false }
          },
          {
            label: 'Exam Type', name: 'examTypeId',
            options: {
              display: 'excluded',
              filterOptions: { names: [...examTypeDictionary.map(d => d.label || '')] },
            }
          },
          {
            label: 'Exam', name: 'examId',
            options: {
              display: 'excluded',
              filterOptions: { names: [...examDictionary.map(d => d.label || '')] },
            }
          }, {
            label: 'Level', name: 'levelId',
            options: {
              display: 'excluded',
              filterOptions: { names: [...levelDictionary.map(d => d.label || '')] },
            }
          },
          {
            label: 'Unit', name: 'unitId', options: {
              display: 'excluded',
              filterOptions: { names: [...unitDictionary.map(d => d.label || '')] },
            }
          },
          {
            label: 'Essay Evaluation', name: 'isEvaluated',
            options: {
              display: 'excluded',
              filterOptions: { names: ['false', 'true'] },
            },
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}

        customOptions={{
          filter: true,
          onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            setPageNumber(1)
            let newFilters: Map<string, string> = new Map()
            for (let i = 0; i < filterList.length; i++) {
              if (filterList[i].length > 0) {
                if (i == 7) newFilters.set("ExamTypeId", examTypeDictionary.find(j => j.label == filterList[i][0].toString())?.value.toString() || '')
                if (i == 8) newFilters.set("ExamId", examDictionary.find(j => j.label == filterList[i][0].toString())?.value.toString() || '')
                if (i == 9) newFilters.set("LevelId", levelDictionary.find(j => j.label == filterList[i][0].toString())?.value.toString() || '')
                if (i == 10) newFilters.set("UnitId", unitDictionary.find(j => j.label == filterList[i][0].toString())?.value.toString() || '')
                if (i == 11) newFilters.set("IsEvaluated", filterList[i][0].toString())
              }
            }
            setFilters(newFilters)
          },
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />

    </>
  )
}

import { ILevelModel } from '../../models/responses/LevelModel'
import API from './Api'

class LevelApi {

  async GetLevelDictionary() {
    return await API.get<ILevelModel[]>(`e/Trainer/Levels/dictionary`)
  }

}

export default new LevelApi()

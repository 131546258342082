import { IReadAnnouncementRequestModel } from '../../models/requests/AnnouncementRequestModel'
import { IAnnouncementModel } from '../../models/responses/AnnouncementModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class AnnouncementApi {
  async GetAnnouncements(userId: string, filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IAnnouncementModel[]>>(`e/Trainer/Announcements/user/${userId}${generateQueryParams(filter)}`)
  }

  async GetAnnouncementAlerts(userId: string, filter: IPaginationFilter) {
    return await API.get<IAnnouncementModel[]>(`e/Trainer/Announcements/alert/${userId}${generateQueryParams(filter)}`)
  }

  async ReadAnnouncement(model: IReadAnnouncementRequestModel) {
    return await API.put('e/Trainer/Announcements/read/' + model.id, model)
  }
}

export default new AnnouncementApi()

import { renewToken } from "../../app/modules/auth/redux/AuthService";
import * as auth from '../../app/modules/auth/redux/AuthRedux'
const logger = require("../../app/helpers/LogHelper");

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const accessToken = localStorage.getItem("accessToken")

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      config.baseURL = process.env.REACT_APP_API_URL || '/api'
      return config
    },
    (err: any) => Promise.reject(err)
  )
  // Response interceptor for API calls
  axios.interceptors.response.use(
    async function (response: any) {
      return response
    },
    async function (error: { config: any; response: { status: number; }; }) {
      const originalRequest = error.config
      if (error.response.status === 400) {
        var dataError: any = error.response;
        if (Array.isArray(dataError.data.errors) && dataError.data.errors[0] != null) {
          alert(dataError.data.errors[0]);
        } else {
          alert("There is a problem")
        }
        console.error("ERROR 400", dataError.data.errors)
        return Promise.reject(error)
      }
      else if (error.response.status === 401 && !originalRequest._retry) {
        console.error("ERROR 401", error)
        renewToken().then(data => {
          store.dispatch(auth.actions.refreshToken(data?.access_token!))
        }).catch(error => {
        })
      } else if (error.response.status === 403 && !originalRequest._retry) {
      } else {
        return Promise.reject(error)
      }
    }
  )
}
import { FC, useEffect, useState } from 'react'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { IAnnouncementModel } from '../../../../models/responses/AnnouncementModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { SelectModel } from '../../../models/SelectModel'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { ITrainerPerformanceModel } from '../../../../models/responses/TrainerPerformanceModel'
import { BasicTable } from '../../../components/tables/BasicTable'
import { EnumToList } from '../../../helpers/EnumHelper'
import moment from 'moment'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { IGetTrainerPerformanceRequestModel } from '../../../../models/requests/GetTrainerPerformanceRequestModel'
import { StatisticsWidget5 } from '../../../../_metronic/partials/widgets'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const PerformanceListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [days, setDays] = useState<ITrainerPerformanceModel[]>()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  let now = new Date();
  let startTime = moment().startOf('month');
  let endTime = moment().endOf('month');

  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)

  const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
    setStart(startDate)
    setEnd(endDate)
    getDatas(startDate, endDate)
  }

  let ranges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 3 Days': [moment().subtract(3, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  let local = {
    "format": "YYYY/MM/DD",
    "sundayFirst": false,
  }

  async function getDatas(start: moment.Moment, end: moment.Moment) {
    var model: IGetTrainerPerformanceRequestModel = {
      startTime: start.format("YYYY-MM-DDT00:00:00"),
      endTime: end.format("YYYY-MM-DDT23:59:59"),
      trainerId: user.id
    }
    var response = await TrainerApi.GetTrainerPerformance(user.id, model)
    setDays(response.data)
  }

  useEffect(() => {
    getDatas(startTime, endTime)
  }, [refresh])

  var totalPleasure = days?.reduce((a, b) => a + (parseFloat(b.pleasure) || 0), 0) || 0
  var totalActiveDay = days?.filter(i => parseFloat(i.pleasure) > 0).reduce((a, b) => a + 1, 0) || 0

  var avaragePleasure = totalPleasure / totalActiveDay;

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen032.svg'
            color='primary'
            iconColor='white'
            title={days?.reduce((a, b) => a + (parseFloat(b.price) || 0), 0).toFixed(2).toString() || ''}
            description='Total Price'
          />
        </div>

        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color='dark'
            iconColor='white'
            title={days?.reduce((a, b) => a + (b.talkingMinutes || 0), 0).toString() || ''}
            description='Total Minutes'
          />
        </div>
        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color='warning'
            iconColor='white'
            title={days?.reduce((a, b) => a + (b.troubledMinutes || 0), 0).toString() || ''}
            description='Troubled Minutes'
          />
        </div>

        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/finance/fin006.svg'
            color='info'
            iconColor='white'
            title={days?.reduce((a, b) => a + (b.comment || 0), 0).toString() || ''}
            description='Total Comment'
          />
        </div>

        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='danger'
            iconColor='white'
            title={(avaragePleasure || 0).toFixed(1).toString()}
            description='Avarage Pleasure'
          />
        </div>
      </div>
      {/* end::Row */}
      <div className='col-lg-3'>
        <label className='form-label fw-bolder'>Date Range</label>
        <DateTimeRangeContainer
          ranges={ranges}
          start={start}
          style={{
            darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
          }}
          end={end}
          local={local}
          applyCallback={applyCallback}
        >
          <input
            id="formControlsTextB"
            className='form-control form-control-solid'
            value={`${start.format(local.format)} - ${end.format(local.format)}`}
            type="text"
          />
        </DateTimeRangeContainer>
      </div>
      <BasicTable
        title='Performance Report'
        data={days}
        customOptions={
          { rowsPerPage: 100 }
        }
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{dataIndex + 1}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Date',
            name: 'date',
            options: {
              customBodyRender: (value) => {
                return <>{value.substring(0, 10)}</>
              }
            }
          },
          {
            label: 'Pleasure',
            name: 'pleasure',
            options: {
              customBodyRender: (value) => {
                return <h5 className="mb-1">{value && value.toFixed(1) + "/5"}</h5>
              },
            },
          },
          {
            label: 'Comment',
            name: 'comment',
            options: {
              customBodyRender: (value) => {
                return <h5 className="mb-1">{value}</h5>
              },
            },
          },
          {
            label: 'Talking Minutes',
            name: 'talkingMinutes',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <h5 className="mb-1">{days![dataIndex].talkingMinutes}</h5>
              },
            },
          },
          {
            label: 'Troubled Minutes',
            name: 'troubledMinutes',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <h5 className="mb-1">{days![dataIndex].troubledMinutes}</h5>
              },
            },
          },
          {
            label: 'Price',
            name: 'price',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <h5 className="mb-1">{parseFloat(days![dataIndex].price).toFixed(2)}</h5>
              },
            },
          },
        ]}
      />
    </>
  )
}

export { PerformanceListModal }

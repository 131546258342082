import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { CallPlanListModal } from './_modals/CallPlanListModal'

const CallPlanPage: FC = () => {
  const [refresh, setRefresh] = useState<number>(0)
  const onClickItem = (value: string, name: string, type: string) => { }

  return (
    <>
      <CallPlanListModal refresh={refresh} onClickItem={onClickItem} />
    </>
  )
}

const CallPlanWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Call Plans</PageTitle>
      <CallPlanPage />
    </>
  )
}

export { CallPlanWrapper }

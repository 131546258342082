/* eslint-disable jsx-a11y/anchor-is-valid */
import { FastField } from 'formik'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ScheduleStatusEnum } from '../../../../enums/ScheduleStatusEnum'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import TopicApi from '../../../../infrastructure/api/TopicApi'
import { IScheduleModel } from '../../../../models/responses/ScheduleModel'
import { ITopicModel, topicInitValues } from '../../../../models/responses/TopicModel'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { RootState } from '../../../../setup'
import { KTSVG } from '../../../../_metronic/helpers'
import { BasicTable } from '../../../components/tables/BasicTable'
import { SelectModel } from '../../../models/SelectModel'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'

type Props = {
    className: string
    schedule: IScheduleModel
    users: IUserDictionaryModel[]
    setScheduleStatus: (status: ScheduleStatusEnum) => void
}

const STUDENT_URL = process.env.REACT_APP_STUDENT_URL

const ScheduleInfoModal: React.FC<Props> = ({ className, schedule, users, setScheduleStatus }) => {
    const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
    const [topic, setTopic] = useState<ITopicModel>(topicInitValues)
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)
    const [submitType, setSubmitType] = useState<ScheduleStatusEnum>(ScheduleStatusEnum.Waiting)

    async function init() {
        setSubmitType(schedule.status)
        if (schedule.topicId != null && schedule.topicId != '') {
            var responseTopic = await TopicApi.GetTopic(schedule.topicId)
            setTopic(responseTopic.data)
        }
    }

    async function startConversation(scheduleId: string) {
        try {
            await ScheduleApi.StartSchedule({ scheduleId: scheduleId });
            setComplete(true)
            setScheduleStatus(ScheduleStatusEnum.Started);
            setSubmitType(ScheduleStatusEnum.Started);
        } catch (error) {
            setLoading(false)
        }
        setLoading(false)
    }



    useEffect(() => {
        if (schedule) init()
    }, [schedule])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder text-dark'>Call Details</span>
                    <span className='text-muted mt-1 fw-bold fs-7'>Information</span>
                </h3>

                <div className='mb-10'>
                    {submitType == ScheduleStatusEnum.Ready ? <SaveChangesButton
                        loading={loading}
                        setLoading={setLoading}
                        complete={complete}
                        title={"Start Conversation"}
                        customClasses={"btn-info me-3"}
                        setComplete={setComplete}
                        valid={true}
                        submit={true}
                        setSubmit={() => {
                            setLoading(true)
                            setTimeout(() => {
                                startConversation(schedule.id!)
                            }, 500)
                        }}
                    />
                        : schedule != undefined && schedule?.meeting != undefined && schedule?.meeting?.startUrl != undefined &&
                        <a className='btn btn-success' href={schedule?.meeting?.startUrl} target={"_blank"}>
                            <i className='fa fa-video'></i> Get Zoom Meeting
                        </a>
                    }
                </div>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body pt-5'>
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label bg-light-success'>
                            <KTSVG
                                path='/media/icons/duotune/abstract/abs027.svg'
                                className='svg-icon-2x svg-icon-success'
                            />
                        </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Text */}
                    <div className='d-flex flex-column'>
                        <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
                            {ScheduleTypeEnum[schedule?.type!]}
                        </a>
                        <span className='text-muted fw-bold'>Call Type</span>
                    </div>
                    {/* end::Text */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label bg-light-warning'>
                            <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-2x svg-icon-warning'
                            />
                        </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Text */}
                    <div className='d-flex flex-column'>
                        <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
                            {schedule?.plannedDate}
                        </a>
                        <span className='text-muted fw-bold'>Call Date</span>
                    </div>
                    {/* end::Text */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label bg-light-primary'>
                            <KTSVG
                                path='/media/icons/duotune/communication/com012.svg'
                                className='svg-icon-2x svg-icon-primary'
                            />
                        </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Text */}
                    <div className='d-flex flex-column'>
                        <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
                            {schedule?.duration} minutes
                        </a>
                        <span className='text-muted fw-bold'>Duration</span>
                    </div>
                    {/* end::Text */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label bg-light-danger'>
                            <KTSVG
                                path='/media/icons/duotune/coding/cod008.svg'
                                className='svg-icon-2x svg-icon-danger'
                            />
                        </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Text */}
                    <div className='d-flex flex-column'>
                        <a href={`${STUDENT_URL}topic/tgo/${topic.id}/${localStorage.getItem("accessToken")}`} target={"_blank"} className='text-dark text-hover-primary fs-6 fw-bolder'>
                            {topic.title}
                        </a>
                        <span className='text-muted fw-bold'>Topic</span>
                    </div>
                    {/* end::Text */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label bg-light-info'>
                            <KTSVG
                                path='/media/icons/duotune/general/gen049.svg'
                                className='svg-icon-2x svg-icon-info'
                            />
                        </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Text */}
                    <div className='d-flex flex-column'>
                        <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
                            {levelDictionary.find(i => i.value == schedule?.levelId!.toString())?.label}
                        </a>
                        <span className='text-muted fw-bold'>Level</span>
                    </div>
                    {/* end::Text */}
                </div>
                {/* end::Item */}

                <br />

                <BasicTable
                    title=""
                    customOptions={{
                        search: false,
                        viewColumns: false,
                        pagination: false
                    }}
                    data={users} columns={[
                        {
                            label: "Name",
                            name: "firstName"
                        }, {
                            label: "Email",
                            name: "email"
                        }
                    ]} />

            </div>
        </div>
    )
}

export { ScheduleInfoModal }

import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { IUserModel, userInitValues } from '../../../../models/responses/UserModel'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetRoles: '[Set Roles] Action',
    SetSelectedUser: '[Set SelectedUser] Action',
    UpdateSelectedUser: '[Update SelectedUser] Action',
    SetNotifUsers: '[Set NotifUsers] Action',
}

export interface IAccountState {
    roles?: Array<string>,
    selectedUser?: IUserModel,
    updateSelectedUser?: boolean,
    notifUsers?: Array<IUserDictionaryModel>
}

const initialAccountState: IAccountState = {
    roles: [],
    selectedUser: userInitValues,
    updateSelectedUser: false,
    notifUsers: []
}

export const reducer = persistReducer(
    {
        storage, key: 'v100-demo1-account',
        whitelist: [],
        blacklist: ['roles', 'selectedUser', 'updateSelectedUser', 'notifUsers']
    },
    (state: IAccountState = initialAccountState, action: ActionWithPayload<IAccountState>) => {
        switch (action.type) {
            case actionTypes.SetRoles: {
                const roles = action.payload?.roles
                return { ...state, roles }
            }
            case actionTypes.SetSelectedUser: {
                const selectedUser = action.payload?.selectedUser
                return { ...state, selectedUser }
            }
            case actionTypes.UpdateSelectedUser: {
                const updateSelectedUser = action.payload?.updateSelectedUser
                return { ...state, updateSelectedUser }
            }
            case actionTypes.SetNotifUsers: {
                const notifUsers = action.payload?.notifUsers
                return { ...state, notifUsers }
            }
            default:
                return state
        }
    }
)

export const actions = {
    setRoles: (roles: Array<string>) => ({ type: actionTypes.SetRoles, payload: { roles } }),
    setSelectedUser: (selectedUser: IUserModel) => ({ type: actionTypes.SetSelectedUser, payload: { selectedUser } }),
    updateSelectedUser: (updateSelectedUser: boolean) => ({ type: actionTypes.UpdateSelectedUser, payload: { updateSelectedUser } }),
    setNotifUsers: (notifUsers: Array<IUserDictionaryModel>) => ({ type: actionTypes.SetNotifUsers, payload: { notifUsers } }),
}

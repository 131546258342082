export enum ScheduleStatusEnum {
    Waiting = 0,
    Completed = 1,
    Canceled = 2,
    Closed = 3,
    NotAnswered = 4,
    Started = 5,
    Ready = 51,
    Postpone = 6,
    TechnicalDifficulty = 7,
    MeetingCreateError = 90
}
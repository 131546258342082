import { FC, useEffect, useState } from 'react'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { SelectModel } from '../../../models/SelectModel'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { ISchedulesModel } from '../../../../models/responses/ScheduleModel'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import { ScheduleStatusEnum } from '../../../../enums/ScheduleStatusEnum'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import * as signalR from "@microsoft/signalr";

const SCHEDULE_CONSUMER_URL = process.env.REACT_APP_SCHEDULE_CONSUMER_URL

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const ScheduleListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [schedules, setSchedules] = useState<PagedResponseModel<ISchedulesModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')
  const [roles, setRoles] = useState<SelectModel[]>([])

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel



  function initSignalR() {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${SCHEDULE_CONSUMER_URL}trainerhub`,
        {
          accessTokenFactory: () => {
            return localStorage.getItem("accessToken");
          }
        } as signalR.IHttpConnectionOptions)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();


    hubConnection.start().then(a => {
      console.log("success", "Connected Web Socket");
      // if (hubConnection.connectionId) {
      //   hubConnection.invoke("CheckAsync", hubConnection.connectionId);
      // }

      hubConnection.on("CheckActiveCall", data => {
        getSchedules();
      });
    }).catch(err => console.error("Web socket", err));

  }

  async function getSchedules() {

    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    var response = await ScheduleApi.GetPreviousSchedules(paginationFilter, { trainerId: user.id })
    setSchedules(response.data)
  }

  useEffect(() => {
    initSignalR();

    getSchedules()
  }, [refresh, pageNumber, pageSize, searchText, sort])


  return (
    <>
      <GenericTable
        title=''
        data={schedules?.items}
        size={pageSize}
        currentTotalRecords={schedules?.totalRecords!!}
        currentPageNumber={schedules?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Planned',
            name: 'plannedDate'
          },
          {
            label: 'Duration',
            name: 'duration'
          },
          {
            label: 'Type',
            name: 'type',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return (ScheduleTypeEnum[parseInt(schedules?.items[dataIndex].type.toString() || '')])
              },
            },
          },
          {
            label: 'Status',
            name: 'status',
            options: {
              customBodyRenderLite: (dataIndex) => {
                let color = "secondary"
                switch (schedules?.items[dataIndex].status) {
                  case ScheduleStatusEnum.Completed:
                    color = "success"
                    break;
                  case ScheduleStatusEnum.Canceled:
                    color = "info"
                    break;
                  case ScheduleStatusEnum.MeetingCreateError:
                  case ScheduleStatusEnum.TechnicalDifficulty:
                    color = "danger"
                    break;
                  case ScheduleStatusEnum.NotAnswered:
                    color = "warning"
                    break;
                  case ScheduleStatusEnum.Closed:
                    color = "dark"
                    break;
                  case ScheduleStatusEnum.Ready:
                  case ScheduleStatusEnum.Started:
                    color = "primary"
                    break;
                  default:
                    break;
                }

                return (
                  <span className={`badge bg-${color}`}>
                    {ScheduleStatusEnum[parseInt(schedules?.items[dataIndex].status.toString() || '')]}
                  </span>)
              },
            },
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                var buttons: TableHelperButtonEnum[] = []
                var links: DictionaryModel[] = []
                if (schedules?.items[dataIndex].status == ScheduleStatusEnum.Completed
                  && !schedules?.items[dataIndex].isHaveTrainerComment) buttons.push(TableHelperButtonEnum.Reply)

                if ([ScheduleStatusEnum.Started, ScheduleStatusEnum.Ready].some(s => s == schedules?.items[dataIndex].status)) links.push({ type: 'eye', value: '/call/' + schedules?.items[dataIndex]?.id!! })
                return (
                  <TableHelperButtons
                    itemId={schedules?.items[dataIndex]?.id!!}
                    itemParam={schedules?.items[dataIndex]?.plannedDate!!}
                    types={[...buttons]}
                    links={[...links]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={
          {
            rowsPerPageOptions: [5],
            search: false,
            sort: false,
            viewColumns: false
          }
        }
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { ScheduleListModal }

import { FC, useEffect, useState } from 'react'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { SelectModel } from '../../../models/SelectModel'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { ISchedulesModel } from '../../../../models/responses/ScheduleModel'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import { ScheduleStatusEnum } from '../../../../enums/ScheduleStatusEnum'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { ScheduleViewModal } from '../../../pages/schedules/_modals/ScheduleViewModal'

type Props = {
  studentId: string
}

const StudentSchedulesListModal: FC<Props> = ({ studentId }) => {
  const [itemId, setItemId] = useState('')
  const [refresh, setRefresh] = useState<number>(0)

  const [showView, setShowView] = useState(false)
  const handleCloseView = () => setShowView(false)
  const handleShowView = () => setShowView(true)

  const [schedules, setSchedules] = useState<PagedResponseModel<ISchedulesModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')
  const [roles, setRoles] = useState<SelectModel[]>([])

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    switch (type) {
      case 'view':
        handleShowView()
        break
    }
  }


  useEffect(() => {
    async function getSchedules() {

      const paginationFilter: IPaginationFilter = {
        page: pageNumber,
        size: pageSize,
        orderBy: sort,
        search: searchText,
      }
      var response = await ScheduleApi.GetPreviousSchedules(paginationFilter, { studentId: studentId })
      setSchedules(response.data)
    }
    if (studentId != '') {
      getSchedules()
    }
  }, [studentId, refresh, pageNumber, pageSize, searchText, sort])


  return (
    <>
      <h5 className='fw-bolder m-0'>Schedules</h5>
      <ScheduleViewModal itemId={itemId} show={showView} handleClose={handleCloseView} />
      <GenericTable
        title=''
        data={schedules?.items}
        size={pageSize}
        currentTotalRecords={schedules?.totalRecords!!}
        currentPageNumber={schedules?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Planned',
            name: 'plannedDate'
          },
          {
            label: 'Duration',
            name: 'duration'
          },
          {
            label: 'Type',
            name: 'type',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return (ScheduleTypeEnum[parseInt(schedules?.items[dataIndex].type.toString() || '')])
              },
            },
          },
          {
            label: 'Status',
            name: 'status',
            options: {
              customBodyRenderLite: (dataIndex) => {
                let color = "secondary"
                switch (schedules?.items[dataIndex].status) {
                  case ScheduleStatusEnum.Completed:
                    color = "success"
                    break;
                  case ScheduleStatusEnum.Canceled:
                    color = "info"
                    break;
                  case ScheduleStatusEnum.TechnicalDifficulty:
                    color = "danger"
                    break;
                  case ScheduleStatusEnum.NotAnswered:
                    color = "warning"
                    break;
                  case ScheduleStatusEnum.Closed:
                    color = "dark"
                    break;
                  case ScheduleStatusEnum.Ready:
                  case ScheduleStatusEnum.Started:
                    color = "primary"
                    break;
                  default:
                    break;
                }

                return (
                  <span className={`badge bg-${color}`}>
                    {ScheduleStatusEnum[parseInt(schedules?.items[dataIndex].status.toString() || '')]}
                  </span>)
              },
            },
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {

                return (
                  <TableHelperButtons
                    itemId={schedules?.items[dataIndex]?.id!!}
                    itemParam={schedules?.items[dataIndex]?.plannedDate!!}
                    types={[TableHelperButtonEnum.View]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={
          {
            rowsPerPageOptions: [5],
            search: false,
            sort: false,
            viewColumns: false
          }
        }
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />

    </>
  )
}

export { StudentSchedulesListModal }

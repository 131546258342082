import { ScheduleStatusEnum } from '../../enums/ScheduleStatusEnum'
import { ScheduleTypeEnum } from '../../enums/ScheduleTypeEnum'

export interface IScheduleModel {
    id?: string,
    studentIds: string[],
    trainerId: string,
    type: ScheduleTypeEnum,
    levelId?: number,
    meeting?: IScheduleMeetingModel,
    topicId?: string,
    capacity: number,
    duration: number,
    empty: number,
    status: ScheduleStatusEnum
    plannedDate: string,
    completedDate?: string,
    details: IScheduleDetailModel[],
    trainerComments: ITrainerCommentModel[],
    studentComments: IStudentCommentModel[],
    records: IScheduleRecordModel[]
}

export interface IScheduleMeetingModel {
    meetingId: string,
    topic: string,
    hostEmail: string,
    startTime: string,
    joinUrl: string,
    startUrl: string,
    duration: number
}

export interface IScheduleDetailModel {
    createdAt: string
    trainerId: string
}

export interface IScheduleRecordModel {
    url: string
}

export interface ITrainerCommentModel {
    trainerId: string
    message: string
    date: string
}

export interface IStudentCommentModel {
    message: string
    date: string
    pleasure: 0
    difficulty: 0
    useful: 0
    quality: 0
    prepared: 0
}

export interface IBestTrainerModel {
    trainerId: string,
    point: string
}

const scheduleInitValues: IScheduleModel = {
    id: undefined,
    studentIds: [],
    trainerId: '',
    type: ScheduleTypeEnum.OneTime,
    levelId: 0,
    meeting: undefined,
    topicId: '',
    capacity: 0,
    duration: 0,
    empty: 0,
    status: ScheduleStatusEnum.Waiting,
    plannedDate: '',
    completedDate: '',
    details: [],
    trainerComments: [],
    studentComments: [],
    records: []
}

export interface ISchedulesModel {
    id?: string,
    studentIds: string[],
    trainerId: string,
    type: ScheduleTypeEnum,
    levelId?: number,
    topicId?: string,
    meetingId?: string,
    duration?: number,
    capacity: number,
    empty: number,
    status: ScheduleStatusEnum
    plannedDate: string,
    completedDate?: string,
    isHaveTrainerComment: boolean
}

const schedulesInitValues: ISchedulesModel = {
    id: undefined,
    studentIds: [],
    trainerId: '',
    type: ScheduleTypeEnum.Planned,
    levelId: undefined,
    topicId: undefined,
    meetingId: undefined,
    duration: 0,
    capacity: 0,
    empty: 0,
    status: ScheduleStatusEnum.Waiting,
    plannedDate: '',
    completedDate: '',
    isHaveTrainerComment: false
}

export { scheduleInitValues, schedulesInitValues }

import { FC, useEffect, useState } from 'react'
import ResultApi from '../../../../infrastructure/api/ResultApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import {
  IResultModel,
  resultInitValues,
} from '../../../../models/responses/ResultModel'
import { IResultRequestModel } from '../../../../models/requests/ResultRequestModel'
import { ResultFormValidations } from '../../../validations/ResultFormValidations'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import parse from 'html-react-parser'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const ResultFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [result, setResult] = useState<IResultModel>(resultInitValues)
  const [comment, setComment] = useState('')

  const updateResult = (fieldsToUpdate: Partial<IResultModel>) => {
    const updatedData = { ...result, ...fieldsToUpdate }
    setResult(updatedData)
  }

  const formik = useFormik<IResultModel>({
    initialValues: result,
    enableReinitialize: true,
    validationSchema: ResultFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IResultRequestModel = {
          id: values.id,
          score: values.score,
          copyPercentage: parseInt(values.copyPercentage),
          evaluation: comment
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IResultRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === '')
        result = undefined
      else {
        result = await ResultApi.UpdateResult(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IResultModel
    if (itemId !== '') {
      var response = await ResultApi.GetResult(itemId)
      result = response.data
    } else {
      result = resultInitValues
    }
    setResult(result)
  }

  useEffect(() => {
    setComment('')
    formik.resetForm()
    if (show) init()
  }, [itemId, show])


  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Reply Writing</h1>
              <div className='text-muted fw-bold fs-5'>
                Exam Management <small>Educall</small>
              </div>
            </div>
            {result.isWriting &&
              <>
                <div className='mb-10'>
                  <div className='alert alert-custom bg-light-primary'>
                    <h4 className="alert-heading">Essay</h4>
                    {parse(result.choices[0].answers.join(''))}</div>

                  <label className='form-label fw-bolder required'>Reply</label>
                  <DefaultEditor
                    toolbars={[EditorToolbarEnum.Basic]}
                    setData={setComment}
                    data={comment} />
                </div>
                <div className='row'>
                  <div className='col'>
                    <label className='form-label fw-bolder required'>Copy Percentage</label>
                    <input
                      {...formik.getFieldProps('copyPercentage')}
                      type='number'
                      name='copyPercentage'
                      className='form-control form-control-solid'
                      placeholder='{0-100}'
                    />
                    <FormikValidationError touched={formik.touched.copyPercentage} error={formik.errors.copyPercentage} />
                  </div>
                  <div className='col'>
                    <label className='form-label fw-bolder required'>Score</label>
                    <input
                      {...formik.getFieldProps('score')}
                      type='number'
                      name='score'
                      className='form-control form-control-solid'
                      placeholder='{0-30}'
                    />
                    <FormikValidationError touched={formik.touched.score} error={formik.errors.score} />
                  </div>
                </div>
              </>
            }
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { ResultFormModal }

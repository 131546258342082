import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { ScheduleFormModal } from './_modals/ScheduleFormModal'
import { ScheduleInfoModal } from './_modals/ScheduleInfoModal'
import UserApi from '../../../infrastructure/api/UserApi'
import { IUserDictionaryModel } from '../../../models/responses/UserDictionaryModel'
import { IScheduleModel } from '../../../models/responses/ScheduleModel'
import ScheduleApi from '../../../infrastructure/api/ScheduleApi'
import { ScheduleStudentDetailsFormModal } from './_modals/ScheduleStudentDetailsFormModal'
import { useParams } from 'react-router-dom'
import { ScheduleStatusEnum } from '../../../enums/ScheduleStatusEnum'


const ActiveCallPage: FC = ({ }) => {
  const { scheduleId } = useParams<string>();

  const [users, setUsers] = useState<IUserDictionaryModel[]>([])
  const [schedule, setSchedule] = useState<IScheduleModel>()
  const [itemName, setItemName] = useState('')

  const [refresh, setRefresh] = useState<number>(0)
  const [scheduleStatus, setScheduleStatus] = useState<ScheduleStatusEnum>(ScheduleStatusEnum.Waiting)
  async function init() {
    var response = await ScheduleApi.GetSchedule(scheduleId!)
    if (![ScheduleStatusEnum.Started, ScheduleStatusEnum.Ready, ScheduleStatusEnum.Waiting].some(s => s == response.data.status))
      document.location.href = "/error/404"

    if (response.data.studentIds.length > 0) {
      getUsers(response.data.studentIds);
    }
    setSchedule(response.data)
    setScheduleStatus(response.data.status)
  }

  async function getUsers(studentIds: string[]) {
    var response = await UserApi.GetUserDictionaryIds(studentIds)
    setUsers(response.data)
  }

  useEffect(() => {
    init();
  }, [refresh, scheduleId])


  return (
    <>
      {schedule &&
        <div className='row gy-5 g-xl-8'>
          <div className='col-md-4'>
            <ScheduleInfoModal setScheduleStatus={init} users={users} schedule={schedule!} className={'card-xl-stretch mb-xl-8'} />
          </div>
          {scheduleStatus == ScheduleStatusEnum.Started &&
            <div className='col-md-8'>
              <ScheduleFormModal refresh={refresh} schedule={schedule!} />
            </div>
          }
          <div className={`col${scheduleStatus == ScheduleStatusEnum.Started ? '' : '-md-8'}`} >
            <ScheduleStudentDetailsFormModal schedule={schedule!} refresh={1} onClickItem={alert} />
          </div>
        </div>
      }
    </>
  )
}

const ActiveCallWrapper: FC = ({ }) => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Active Call</PageTitle>
      <ActiveCallPage />
    </>
  )
}

export { ActiveCallWrapper }

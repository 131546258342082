import { ITopicModel } from '../../models/responses/TopicModel'

import API from './Api'

class TopicApi {
  async GetTopic(id: string) {
    return await API.get<ITopicModel>('t/Trainer/Topics/' + id)
  }
}

export default new TopicApi()

import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { PerformanceListModal } from './_modals/PerformanceListModal'

const PerformancePage: FC = () => {
  const [refresh, setRefresh] = useState<number>(0)

  const onClickItem = (value: string, name: string, type: string) => {

  }

  return (
    <>
    
      <div className='card mb-5 mb-xl-8'>        
        <div className='card-body py-3 mt-6'>
          <PerformanceListModal refresh={refresh} onClickItem={onClickItem} />
        </div>
      </div>
    </>
  )
}

const PerformanceWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Performance</PageTitle>
      <PerformancePage />
    </>
  )
}

export { PerformanceWrapper }

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { ResultWrapper } from '../../pages/results/ResultWrapper'
import { ScheduleWrapper } from '../../pages/schedules/ScheduleWrapper'
import { Link } from 'react-router-dom'
import { BestTrainersListModal } from '../schedules/_modals/BestTrainersListModal'
import { UserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IUserModel } from '../../../models/responses/UserModel'
import * as account from '../../modules/accounts'
import UserApi from '../../../infrastructure/api/UserApi'
import { ClaimTypeEnum } from '../../../enums/ClaimTypeEnum'
import { IUserDictionaryModel } from '../../../models/responses/UserDictionaryModel'

const CDN_URL = process.env.REACT_APP_CDN_URL

const DashboardPage: FC = () => {

  const [itemId, setItemId] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [refresh, setRefresh] = useState<number>(0)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    switch (type) {
      default:
        handleShowForm()
        break
    }
  }

  const dispatch = useDispatch()

  const userAuth: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)


  async function getUser(id: string) {


    var response = await UserApi.GetUser(id)
    var user = response.data;

    var consultantId = user.details?.find(i => i.type == ClaimTypeEnum.ConsultantId)?.value!!;

    if (user.consultantName == undefined && consultantId != undefined && (user.consultantName == undefined)) {

      var responseUsers = await UserApi.GetUserDictionaryIds([consultantId])
      if (responseUsers != null && responseUsers.data != null) {
        var dataUsers: IUserDictionaryModel[] = responseUsers.data
        user.consultantName = `${dataUsers[0].firstName} ${dataUsers[0].lastName}`
        dispatch(account.actions.setSelectedUser(user));
      }
      const currentAvatar = user.details?.find(i => i.type == "Photo")?.value!!;
      user.photo = (currentAvatar && currentAvatar != "" ? `${CDN_URL}images/avatar/${currentAvatar}` : toAbsoluteUrl('/media/avatars/blank.png'))
    }
  }


  async function init() {
    getUser(userAuth.id);
  }

  useEffect(() => {

    init();

  }, []);


  return (
    <>
      <PageTitle breadcrumbs={[]}>DASHBOARD</PageTitle>
      <div className='row gy-5 g-xl-8'>

        <div className='col-xl-4'>

          <div className={`card card-xl-stretch mb-xl-8`}>

            <div className={`card-header border-0 py-5 bg-danger`}>

            </div>
            <div className='card-body p-0'>
              <div className={`px-9 pt-2 card-rounded mt-n5 h-150px w-100 bg-danger`}>
                <div className='d-flex text-center flex-column text-white'>
                  <span className='fw-bold fs-7'>Your Consultant</span>
                  <span className='fw-bolder fs-2x pt-1'>{user.consultantName}</span>
                </div>
              </div>
              <div className='card-p mt-n20 position-relative'>

                <div className='row g-0'>
                  <div className='col bg-light-warning px-6 py-8 rounded-2 mb-7'>
                    <Link className='text-warning fw-bold fs-6 mt-2' to={'/performance'}>
                      <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-3x svg-icon-warning d-block my-2'
                      />
                      Performance
                    </Link>
                  </div>

                </div>
                <div className='row g-0'>
                  <div className='col bg-light-danger px-6 py-8 rounded-2 me-7'>
                    <Link className='text-danger fw-bold fs-6 mt-2' to={'/tutorials'}>
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs027.svg'
                        className='svg-icon-3x svg-icon-danger d-block my-2'
                      />
                      Tutorials
                    </Link>
                  </div>
                  <div className='col bg-light-success px-6 py-8 rounded-2'>
                    <Link className='text-success fw-bold fs-6 mt-2' to={'/announcements'}>
                      <KTSVG
                        path='/media/icons/duotune/communication/com010.svg'
                        className='svg-icon-3x svg-icon-success d-block my-2'
                      />
                      Announcement
                    </Link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xxl-8'>

          <ScheduleWrapper />

        </div>
      </div>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <div className={`card card-xl-stretch mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0'>
              <h3 className='card-title fw-bolder text-dark'>Ranking In This Month</h3>
            </div>
            <div className='card-body pt-2'>
              <BestTrainersListModal />

            </div>
          </div>
        </div>
        <div className='col-xxl-8'>
          <ResultWrapper />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className="alert alert-dismissible bg-info d-flex flex-column flex-sm-row p-5 mb-8">
            <span className="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"><i className='fa fa-check'></i></span>
            <div className="d-flex flex-column text-light pe-0 pe-sm-10">
              <h5 className="mb-1">Keep in mind that</h5>
              <span>Student feedback is very important to help Educall to choose the best trainers(you) and give the best education to the students.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {

  const intl = useIntl()
  return (
    <>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }

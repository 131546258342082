import { DayOfWeekEnum } from "../../enums/DayOfWeekEnum"
import { ScheduleTypeEnum } from "../../enums/ScheduleTypeEnum"

export interface ITrainerModel {
    id?: string
    isFreezeAccount: boolean
    freezeAccountDate: string
    trainerPlans: ITrainerPlanModel[]
    availablePlans: ITrainerPlanModel[]
    schedulePlans: ISchedulePlanModel[]
    responsibleLevels: number[]
}

const trainerInitValues: ITrainerModel = {
    id: undefined,
    isFreezeAccount: false,
    freezeAccountDate: '',
    availablePlans: [],
    trainerPlans: [],
    schedulePlans: [],
    responsibleLevels: []
}


export interface ITrainerPlanModel {
    id?: string,
    dayOfWeek: DayOfWeekEnum,
    startDate: string,
    endDate: string,
    duration: number
}

export interface IScheduleTopicStatus {
    topicId: string,
    isComplete: boolean
}

export interface ISchedulePlanModel {
    id?: string,
    title: string,
    studentId: string,
    dayOfWeek: DayOfWeekEnum,
    startDate: string,
    endDate: string,
    duration: number,
    capacity: number,
    empty: number,
    studentIds: string[],
    scheduleType: ScheduleTypeEnum,
    topicId?: string,
    topics?: IScheduleTopicStatus[],
    levelId?: number,
    trainerId?: string
}

const trainerPlanInitValues: ITrainerPlanModel = {
    id: undefined,
    dayOfWeek: DayOfWeekEnum.Monday,
    startDate: '',
    endDate: '',
    duration: 0
}

export { trainerInitValues, trainerPlanInitValues }

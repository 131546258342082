import { FC, useEffect, useState } from 'react'
import ResultApi from '../../../../infrastructure/api/ResultApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import {
  IResultModel,
  resultInitValues,
} from '../../../../models/responses/ResultModel'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { ITrainerCommentRequestModel } from '../../../../models/requests/TrainerCommentRequestModel'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const TrainerCommentFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [result, setResult] = useState<IResultModel>(resultInitValues)
  const [comment, setComment] = useState('')

  const formik = useFormik({
    initialValues: result,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: ITrainerCommentRequestModel = {
          scheduleId: itemId,
          isAutoComment: false,
          comment: comment
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: ITrainerCommentRequestModel) {
    try {
      console.log("tester", model);

      var result = undefined
      if (model.scheduleId === undefined || model.scheduleId === '')
        result = undefined
      else {
        result = await ScheduleApi.AddTrainerComment(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {

  }

  useEffect(() => {
    setComment('')
    formik.resetForm()
    if (show) init()
  }, [itemId, show])


  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Trainer Comment</h1>
              <div className='text-muted fw-bold fs-5'>
                Schedule Management <small>Educall</small>
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Comment</label>
              <DefaultEditor
                toolbars={[EditorToolbarEnum.Basic]}
                setData={setComment}
                data={comment} />
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { TrainerCommentFormModal }

import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { useLocation } from 'react-router-dom'
import {
  DrawerMessenger,
  RightToolbar,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
import { MenuComponent } from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { AnnouncementAlert } from '../../app/pages/announcements/components/AnnouncementAlert'
import { ITrainerModel, trainerInitValues } from '../../models/responses/TrainerModel'
import TrainerApi from '../../infrastructure/api/TrainerApi'
import { UserModel } from '../../app/modules/auth/models/UserModel'
import { RootState } from '../../setup'
import { useSelector, shallowEqual } from 'react-redux'

const MasterLayout: React.FC = ({ children }) => {
  const { classes } = useLayout()
  const [trainer, setTrainer] = useState<ITrainerModel>(trainerInitValues)
  const userAuth: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  async function init() {
    var trainer = await TrainerApi.GetTrainer(userAuth.id);
    setTrainer(trainer.data)
  }

  useEffect(() => {
    init();

    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>

          <HeaderWrapper />
          <div
            className={clsx(
              'd-flex flex-column-fluid align-items-start',
              classes.contentContainer.join(' ')
            )}
            id='kt_post'
          >
            <AsideDefault />
            <Content>
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                {!trainer.isFreezeAccount ?
                  <>
                    <Toolbar />

                    <AnnouncementAlert />
                    <Outlet />

                  </>
                  :
                  <><div className='row mt-20'>
                    <div className='col'>
                      <div className="alert alert-dismissible bg-primary d-flex flex-column flex-sm-row p-5 mb-8">
                        <span className="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"><i className='fa fa-info'></i></span>
                        <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                          <h5 className="mb-1">Your Account Freeze</h5>
                          <span>Contact your administrator for detailed information.</span>
                        </div>
                      </div>
                    </div>
                  </div></>
                }
              </div>
            </Content>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <RightToolbar />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider >
  )
}

export { MasterLayout }

import { FC, useEffect, useState } from 'react'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { IBestTrainerModel } from '../../../../models/responses/ScheduleModel'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'

const BestTrainersListModal: FC = () => {
  const [trainers, setTrainers] = useState<IBestTrainerModel[]>()
  const [users, setUsers] = useState<IUserDictionaryModel[]>([])

  async function init() {
    var response = await ScheduleApi.GetBestTrainers()
    setTrainers(response.data)

    var responseUsers = await UserApi.GetUserDictionaryIds(response.data.map(i => i.trainerId!!))
    var dataUsers: IUserDictionaryModel[] = responseUsers.data
    setUsers(dataUsers)
  }

  useEffect(() => {
    init()
  }, [])


  return (
    <>
      {trainers?.map((item, indexRank) => {
        return (
          <div className='d-flex align-items-center mb-7' key={indexRank}>
            <div className='symbol symbol-50px me-5'>
              <img src={toAbsoluteUrl('/media/avatars/blank.png')} className='' alt='' />
            </div>
            <div className='flex-grow-1'>
              <span className='text-dark fw-bolder text-hover-primary fs-6'>
                {users.find(i => i.id == item.trainerId)?.firstName + " " + users.find(i => i.id == item.trainerId)?.lastName.substring(0, 1)}.
              </span>
              <span className='text-muted d-block fw-bold'>Trainer</span>
            </div>
            <span className='badge badge-light fw-bolder my-2'>{item.point}/5</span>
          </div>
        )
      })}
    </>
  )
}

export { BestTrainersListModal }

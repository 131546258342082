import * as Yup from 'yup'
const ResultFormValidations = () => {
    return Yup.object().shape({
        copyPercentage: Yup.number()
            .min(0, 'Min value 0.')
            .max(100, 'Max value 100.'),
        score: Yup.number()
            .min(0, 'Min value 0.')
            .max(30, 'Max value 30.'),
    })
}

export { ResultFormValidations }
import { IUserModel } from '../../models/responses/UserModel'
import API from './Api'

class UserApi {
 
  async GetUserDictionaryIds(ids: string[]) {
    return await API.post('i/Trainer/Users/dictionary/ids', ids)
  }

  async GetUser(id: string) {
    return await API.get<IUserModel>('i/Trainer/Users/' + id)
  }

}

export default new UserApi()

import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { DeleteModal } from '../../components/modals/DeleteModal'
import VideoApi from '../../../infrastructure/api/VideoApi'
import { PagedResponseModel } from '../../../models/responses/PagedResponseModel'
import { IVideoModel } from '../../../models/responses/VideoModel'
import { IPaginationFilter } from '../../../models/requests/PaginationFilter'

const TutorialPage: FC = () => {
  const [itemId, setItemId] = useState(0)
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const onClickItem = (value: number, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  const [videos, setVideos] = useState<PagedResponseModel<IVideoModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(9999)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('order_asc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    async function getVideos() {
      var response = await VideoApi.GetVideos(paginationFilter)
      setVideos(response.data)
    }
    getVideos()
  }, [])

  return (
    <>
      <div className="alert alert-dismissible bg-success d-flex flex-column flex-sm-row p-5 mb-10">
        <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0"><i className='fa fa-announcement'></i></span>
        <div className="d-flex flex-column text-light pe-0 pe-sm-10">
          <h5 className="mb-1">CEO's Message</h5>
          <span>Dear Colleagues, Thank you for being a part of Educall Academy. If you have good internet access and silent place, you can work at Educall Academy from any place in the World. Teachers are our face. You have a very important duty. We want our students to speak. They have difficulty mostly with understanding and speaking. They have knowledge but they can’t talk fluently. We will make them relax and comfortable when they are talking. We have a quality management system. You giving feedback to your students, your students give you pleasure feedback as well. We monitor pleasure points to keep our quality level high. Our students and our teachers happy to be part of educall. Because it gives you the freedom to access the system any place in the world. You are most welcome to be Teacher at Educall Academy and If you need to reach me I will available for you. Sincerely ,
            <br />
            <b>Tarık SONMEZ / CEO<br />tarik@educall.com.tr</b>
          </span>
        </div>
      </div>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>Tutorials</h3>
        </div>

        <div className='card-body py-3'>
          <h5>a.) Useful Links</h5>
          <div className="separator separator-dashed mb-9"></div>
          <ol className="h4">
            <li><a target="_blank" href="https://www.educall.com.tr/uploads/presentations/Educall-Teacher-Comment-Samples.pdf">Training Comment Samples</a></li>
            <li><a target="_blank" href="https://www.educall.com.tr/uploads/presentations/Educall-Zoiper-PC-Settings.pdf">Zoiper Installation Guide</a></li>
          </ol>
          <h5 className='mt-10'>b.) Training Videos</h5>
          <div className="separator separator-dashed mb-9"></div>
          <div className='row'>
            {videos?.items.filter(i => !i.title.startsWith("Example Call")).map((item, index) => {
              return (
                <div className='col-4 mb-5' key={index}>
                  <h5 className='py-2 '>{item.title}</h5>
                  <iframe className='d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative' width="100%" height="300px" src={`https://www.youtube.com/embed/` + item.link.substring(item.link.length - 11, item.link.length)} frameBorder="0" allowFullScreen></iframe>
                </div>)
            })}
          </div>
          <h5 className='mt-10'>c.) Example Calls</h5>
          <div className="separator separator-dashed mb-9"></div>
          <div className='row'>
            {videos?.items.filter(i => i.title.startsWith("Example Call")).map((item, index) => {
              return (
                <div className='col-3 mb-10' key={index}>
                  <h5>{item.title}</h5>
                  <iframe className='d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative' width="100%" height="300px" src={`https://www.youtube.com/embed/` + item.link.substring(item.link.length - 11, item.link.length)} frameBorder="0" allowFullScreen></iframe>
                </div>)
            })}
          </div>
        </div>
      </div>
    </>
  )
}

const TutorialWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'TUTORIAL' })}</PageTitle>
      <TutorialPage />
    </>
  )
}

export { TutorialWrapper }

import { FC, useEffect, useState } from 'react'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { TrainerCheckCallModel } from '../../../../models/responses/TrainerCheckCallModel'
import { StudentResultListModal } from '../../results/_modals/StudentResultListModal'
import { StudentSchedulesListModal } from './StudentSchedulesListModal'
import { IScheduleModel } from '../../../../models/responses/ScheduleModel'
type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  schedule: IScheduleModel
  refresh: number
}

const ScheduleStudentDetailsFormModal: FC<Props> = ({ onClickItem, schedule, refresh }) => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  useEffect(() => {

  }, [refresh])


  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header p-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder text-dark'>Student Details</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Information</span>
          </h3>
        </div>

        <div className='card-body py-3'>

          <div className='mb-10' />
          <StudentSchedulesListModal studentId={schedule.studentIds[0]} />
          <div className='mb-10' />
          <StudentResultListModal studentId={schedule.studentIds[0]} />


        </div>
      </div>
    </>
  )
}

export { ScheduleStudentDetailsFormModal }

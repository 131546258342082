import { FC, useEffect, useState } from 'react'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { EnumToList } from '../../../helpers/EnumHelper'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import { ISchedulePlanModel, ITrainerModel, ITrainerPlanModel, trainerInitValues } from '../../../../models/responses/TrainerModel'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const CallPlanListModal: FC<Props> = ({ onClickItem, refresh }) => {

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel


  const dayOfWeekList = EnumToList(DayOfWeekEnum);

  const [plans, setPlans] = useState<ITrainerPlanModel[]>([])

  const [availablePlans, setAvailablePlans] = useState<ITrainerPlanModel[]>([])
  const [schedulePlans, setSchedulePlans] = useState<ISchedulePlanModel[]>([])

  const init = (async () => {
    var responseTrainer = await TrainerApi.GetTrainer(user.id)
    if (responseTrainer.data.trainerPlans != null)
      setPlans(responseTrainer.data.trainerPlans)
    if (responseTrainer.data.availablePlans != null)
      setAvailablePlans(responseTrainer.data.availablePlans)
    if (responseTrainer.data.schedulePlans != null)
      setSchedulePlans(responseTrainer.data.schedulePlans)
  });

  useEffect(() => {
    init()
  }, [user])

  return (
    <>

      <div className='row mb-7'>
        <div className='col-lg-12'>
          <div className={`card card-xl-stretch mb-xl-8`}>
            <div className='card-body p-0'>
              <div className={`px-9 pt-2 card-rounded h-200px w-100 bg-success`}>
                <div className="d-flex flex-stack">
                  <h3 className="m-0 mt-10 text-white fw-bolder fs-3">Work Plans</h3>
                </div>
              </div>
              <div className='row' style={{ marginTop: '-100px' }}>

                {dayOfWeekList.map((day, dayIndex) => {
                  return (
                    plans.filter(i => i.dayOfWeek == parseInt(day.value.toString())).length > 0 &&
                    <div className='col-4' key={dayIndex}>
                      <div
                        className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                      >
                        <h3 className='mb-5'>{day.label}</h3>
                        {plans.filter(i => i.dayOfWeek == parseInt(day.value.toString())).map((item, index) => {
                          return (
                            <>
                              <div className={'d-flex align-items-center ' + (index != plans.length - 1 ? 'mb-6' : '')} key={index}>

                                <div className='d-flex align-items-center flex-wrap w-100'>
                                  <div className='mb-1 pe-3 flex-grow-1'>
                                    <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                      {item.startDate} - {item.endDate}
                                    </span>
                                    <div className='text-gray-400 fw-bold fs-7'></div>
                                  </div>
                                </div>

                              </div>

                              {schedulePlans.filter(s => s.dayOfWeek == parseInt(day.value.toString()) && s.startDate >= item.startDate && s.endDate <= item.endDate).map((item, index) => {
                                return (
                                  <span key={index} className='badge badge-light-primary mx-1 mb-1'>{ScheduleTypeEnum[item.scheduleType]}: <span className='text-dark'>{item.startDate} - {item.endDate}</span></span>
                                )
                              })}
                              <hr />
                            </>
                          )
                        }
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { CallPlanListModal }

import { IExamDictionaryModel } from '../../models/responses/ExamDictionaryModel'
import { IQuestionModel } from '../../models/responses/QuestionModel'
import API from './Api'

class ExamApi {

  async GetExamDictionary() {
    return await API.get<IExamDictionaryModel[]>(`ex/Trainer/Exams/dictionary`)
  }

  async GetPlacementExam(examTypeId: string, levelId: number) {
    return await API.get<IQuestionModel[]>(`ex/Trainer/Exams/type/${examTypeId}/${levelId}`)
  }
}

export default new ExamApi()

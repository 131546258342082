import { FC, lazy, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { ResultFormModal } from './_modals/ResultFormModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import ResultApi from '../../../infrastructure/api/ResultApi'

const ResultListModal = lazy(() => import('./_modals/ResultListModal'))
const ResultPage: FC = () => {
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const [showView, setShowView] = useState(false)
  const handleCloseView = () => setShowView(false)
  const handleShowView = () => setShowView(true)

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      case 'view':
        handleShowView()
        break
      default:
        handleShowForm()
        break
    }
  }


  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title fw-bolder text-dark'>Waiting for Evaluation</h3>
        </div>

        <div className='card-body py-3'>
          <ResultListModal refresh={refresh} onClickItem={onClickItem} />
          <ResultFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
        </div>
      </div>
    </>
  )
}

const ResultWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <ResultPage />
    </>
  )
}

export { ResultWrapper }
